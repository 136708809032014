.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-label {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.top-bar {
  width: 100%;
  position: fixed;
  top: -50px; /* Start hidden above the screen */
  left: 0;
  color: white;
  text-align: center;
  padding: 10px 0;
  transition: top 0.5s ease-in-out;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-bar.show {
  top: 0; /* Animate to show */
}

.close-button {
  position: absolute;
  right: 20px;
  top: 10px;
}

.close-button:hover {
  color: #ddd;
}

.ql-editor {
  min-height: 200px;
  max-height: 600px; /* Maximum height */
}

.rounded-top {
  border-top-right-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}

.wrap-preview h3 {
  color: #eb670c !important;
}

.wrap-preview a {
  color: #eb670c !important;
}

.wrap-preview img {
  width: 100% !important;
}

.font-weight-bold {
  font-weight: bold;
}

.text-orange {
  color: #eb670c !important;
}
